.settingContainer {
  h1 {
    font-size: 20px;
  }
  p {
    font-size: 12px;
  }

  .listItem {
    margin: 0 0 8px 0;
    .linkTitle {
      font-size: 16px;
    }
    .description {
      font-size: 11px;
    }
  }
}
