.tableContainer {
  margin: 10px 0;
}

.tableContainer table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.tableContainer th,
.tableContainer td {
  padding: 2px;
  text-align: center;
  border: 1px solid #ddd;
}

.tableContainer th {
  font-size: 12px;
  background-color: #f4f4f4;
  color: #333;
  font-weight: bold;
}

.tableContainer td {
  font-size: 12px;
  background-color: #fff;
}

.tableContainer tr:nth-child(even) td {
  background-color: #f9f9f9;
}
