.searchForm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchForm input[type="text"] {
  padding: 4px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 6px;
}

.searchForm input[type="text"]:focus {
  outline: none;
  border-color: #66afe9;
  box-shadow: 0 0 4px rgba(102, 175, 233, 0.6);
}

.searchForm button {
  padding: 4px 4px;
  font-size: 12px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.searchForm button:hover {
  background-color: #0056b3;
}
