.container {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 100vh;
}

.left, .center, .right {
  padding: 20px;
}

.left, .right {
  flex: 1;
}

.center {
  flex: 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.userInfoBox {
  font-size: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 250px;
}

.userInfoBox h2 {
  margin: 10px 0;
  color: #333;
}
