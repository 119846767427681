.searchContainer {
  width: 500px;
  margin: auto;
  padding: 10px;
  h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
  }
  .description {
    text-align: center;
    font-size: 12px;
    color: #595959;
  }
}
.searchResultCount {
  font-size: 12px;
  margin: 8px 0 4px 0;
  color: #595959;
}

.paginationContainerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 20px 0;
}

.paginationContainerFooter {
  flex-grow: 1;
  text-align: center;
}
