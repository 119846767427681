.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  font-size: 10px;
  color: #333;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #e0e0e0;
}

.pagination button:disabled {
  color: #999;
  background-color: #f9f9f9;
  cursor: not-allowed;
}

.pagination span {
  margin: 0 5px;
  font-size: 12px;
  color: #666;
}

.perPageSelector {
  display: flex;
  align-items: center;
}

.perPageSelector label {
  margin-right: 10px;
  font-size: 12px;
  color: #333;
}

.perPageSelector select {
  font-size: 12px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.perPageSelector select:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}
