.dataTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

th, td{
  border: solid 1px;
}

/* header */
th {
  font-size: 10px;
  text-align: center;
  font-weight: normal;
}
.tableIndexHeader {
  width: 4%;
}
.tableReqnoDateHeader {
  width: 12.5%;
}
.tableSongHeader {
  width: 26.5%;
}

.tablePointHeader,
.tableRawPointHeader
{
  padding: 2px 5px;
  width: 9.5%;
}
.tableChartHeader {
  padding: 2px 3px;
  width: 5%;
}
.tableChartLargeHeader {
  padding: 2px 3px;
  width: 6.5%;
}
/* header */

/* body */
td {
  height: 38px;
}
.separateLine {
  border-bottom: 1px solid #000;
}
.linkedContent {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.noData {
  text-align: center;
}
.tableIndex {
  font-size: 9px;
  padding: 2px 4px 2px 4px;
  text-align: right;
}
.tablePoint,
.tableRawPoint
{
  margin: 4px 6px 4px 6px;
  text-align: right;
  font-size: 11px;
}

.tableCharts {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tableChart {
  margin: 2px 3px;
  text-align: right;
  font-size: 10px;
  height: 50%;
}

/* 曲名 / 歌手名 */
.tableSong {
  display: flex;
  flex-direction: column;
  height: 100%;
}
 
.tableSongNameArea {
  height: 61%;
  /* 上下中央揃え */
  display: flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
}
.tableSongNameArea .tableSongName {
  font-size: 10px;
  margin: 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableArtistNameArea {
  height: 39%;
  position: relative;
}
.tableArtistNameArea .tableArtistName {
  font-size: 7px;
  margin: 2px 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tableSongNameArea .tableSongName::after,
.tableArtistNameArea .tableArtistName::after {
  font-size: 10px;
  content: attr(data-full-text);
  position: absolute;
  bottom: 30%;
  left: 30%;
  width: max-content;
  max-width: 500px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: normal;
  word-wrap: break-word;
  z-index: 10;
  transform: translateY(5px);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transition-delay: 0.2s;
}

.tableSongNameArea .tableSongName:hover::after,
.tableArtistNameArea .tableArtistName:hover::after {
  opacity: 1;
  transform: translateY(0);
}
/* 曲名 / 歌手名 */

/* 選曲番号 */
.tableReqnoDate {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tableRequestNoArea {
    height: 61%;
    /* 上下左右中央揃え */
    display: flex;
    align-items: center;
    justify-content: center;
    .tableRequestNo {
      font-size: 10px;
      padding: 2px 4px 2px 4px;
      text-align: center;
    }
  }
  .tableDateKeyArea {
    height: 39%;
    /* 上下左右中央揃え */
    display: flex;
    align-items: center;
    justify-content: center;
    
    .tableDateKey {
      padding: 2px 4px 2px 4px;
      display: flex;
      width: 100%;
      
      .tableDate {
        width: 75%;
        font-size: 7px;
        padding: 0px 2px 0px 0px;
        text-align: left;
      }
      .tableKey {
        width: 25%;
        font-size: 7px;
        padding: 0 0 0 2px;
        text-align: right;
      }
    }
  }
}
/* 選曲番号 */

/* 点数色分け */
.noScoringGame {
  background-color: #888888;
}

.true100 {
  background: #fc1;
  background: -moz-linear-gradient(45deg, #fc1 0%, #ffe 100%);
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%,#fc1), color-stop(100%,#ffe));
  background: -webkit-linear-gradient(45deg, #fc1 0%,#ffe 100%);
  background: -o-linear-gradient(45deg, #fc1 0%,#ffe 100%);
  background: -ms-linear-gradient(45deg, #fc1 0%,#ffe 100%);
  background: linear-gradient(45deg, #fc1 0%,#ffe 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fc1', endColorstr='#ffe',GradientType=1 );
}
.total100 {
  background-color: #fff550;
}

.totalOver99 {
  background-color: #ffa;
}

.totalOver98 {
  background-color: #cfc;
}

.totalOver95 {
  background-color: #aff;
}

.totalOver90 {
  background-color: #ccf;
}

.totalOver85 {
  background-color: #fbf;
}

.totalOver80 {
  background-color: #fcd;
}
/* 点数色分け部分終了 */

/* sort */
.sortableHeader {
  cursor: pointer;
  font-weight: bold;
}

.asc {
  color: rgb(65, 90, 200);
}

.desc {
  color: rgb(245, 29, 22);
}

.none {
  color: #333;
}
