.dxgScoringSummary {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  text-align: left;
}

.dxgScoringSummary h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #304080;
}

.dxgScoringSummary p {
  font-size: 12px;
  margin: 10px 0;
  color: #555;
}
