* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  min-width: 500px;
  width: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 8vh;
  background-color: #304080;
}

.title {
  color: white;
  font-size: 18px;
}

.navLinks {
  display: flex;
  justify-content: space-around;
  width: 40%;
}

.navLinks li {
  /* 上下左右中央揃え */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navLinks li a {
  font-size: 9px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  /* 上下左右中央揃え */
  display: flex;
  justify-content: center;
  align-items: center;
}

.navLinks li button {
  color: white;
  font-weight: bold;
  /* 上下左右中央揃え */
  display: flex;
  justify-content: center;
  align-items: center;
  /* buttonをlink風に */
  cursor: pointer;
  border: none;
  background: none;
}

/* サイドバー */
.burger {
  display: block;
  cursor: pointer;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.burgerNavLinksArea {
  position: absolute;
  right: 0;
  top: 8vh;
  height: 92vh;
  min-width: 140px;
  width: 30vw;
  background-color: #304080;
}

@media screen and (max-width: 515px) {
  .burgerNavLinksArea {
    right: calc(100vw - 515px);
  }
}

.burgerNavLinks {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style: none;
}
.burgerNavLinks li {
  /* 上下左右中央揃え */
  display: flex;
  justify-content: center;
  align-items: center;
}

.burgerNavLinks li a {
  font-size: 12px;
  color: white;
  font-weight: bold;
  text-decoration: none;
  /* 上下左右中央揃え */
  display: flex;
  justify-content: center;
  align-items: center;
}

.burgerNavLinks li button {
  font-size: 12px;
  color: white;
  font-weight: bold;
  /* 上下左右中央揃え */
  display: flex;
  justify-content: center;
  align-items: center;
  /* buttonをlink風に */
  cursor: pointer;
  border: none;
  background: none;
}
